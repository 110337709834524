html,
body,
#root {
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  background-color: black;
}
.app {
  width: 100vw;
  height: 100vh;
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('/src/assets/2dicks3dimensions.png');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed; */
}
.logo {
  margin: 0;
  padding: 0;
  width: 700px;
  height: 900px;
}
.socials {
  color: aliceblue;
  align-self: flex-end;
  justify-self: flex-end;
}
